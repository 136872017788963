import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Formarters } from '../../../../lib/formaters';

export interface AlertFieldsModalProps {
  modalIsOpen: boolean;
  onClose: () => void;
  fieldsWithError: string[];
}

function createFieldsErrorMessage(fields: string[]): any {
  const formattedArray: any[] = [];

  fields.forEach((element) => {
    let newItem = null;

    if (element.split('.').length === 2) {
      newItem = { section: element.split('.')[0], field: element.split('.')[1] };
    } else {
      newItem = { section: element.split('.')[0], type: element.split('.')[1], field: element.split('.')[2] };
    }

    formattedArray.push(newItem);
  });

  return formattedArray;
}

export default function AlertFieldsModal({ modalIsOpen, onClose, fieldsWithError }: AlertFieldsModalProps): JSX.Element {
  const [formattedErrors, setFormattedErrors] = useState<any[]>([]);

  useEffect(() => {
    if (modalIsOpen) {
      const errors = createFieldsErrorMessage(fieldsWithError);
      setFormattedErrors(errors);
    }
  }, [modalIsOpen]);

  return (
    <Dialog
      open={modalIsOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          overflow: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          padding: '0rem 1.5rem',
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        },
      }}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <DialogTitle sx={{ flex: 1, textAlign: 'center', padding: '25px 0px 0px 0px' }}>
          <Typography variant='h6' component='div' sx={{ fontWeight: 'bold' }}>
            Atenção
          </Typography>
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8, color: '#000000' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        Verifique o(s) campo(s) antes de salvar:
        <List sx={{ width: '100%', bgcolor: '#fff' }}>
          {formattedErrors.map((error: any, index: any) => (
            <ListItem
              key={index}
              sx={{
                bgcolor: '#f4f4f4',
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '15px',
                fontSize: '15px',
                color: '#000',
                marginBottom: '10px',
                '&:last-child': {
                  marginBottom: 0,
                },
              }}
            >
              <Box sx={{ flex: 0.8, textAlign: 'center' }}>
                Seção: <span style={{ fontWeight: '700' }}>{Formarters.translateDecisionSectionKey(error.section)}</span>
              </Box>

              <Box sx={{ flex: 1.4, textAlign: 'center' }}>
                {error.type && (
                  <>
                    Tipo: <span style={{ fontWeight: '700' }}>{Formarters.translateDecisionTypeKey(error.type)}</span>
                  </>
                )}
              </Box>
              <Box sx={{ flex: 1.5, textAlign: 'center' }}>
                Campo: <span style={{ fontWeight: '700' }}>{Formarters.translateDecisionFieldKey(error.field)}</span>
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
