/* eslint-disable no-lonely-if */
import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input1 from '../../Atomos/Input1';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { Formarters } from '../../../utils';

export type InputWithTitleProps = {
  title: string;
  tooltipText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  initialAdornment?: string;
  defaultValue?: string;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  validator?: (value: string) => string | null; // Função de validação
  helperText?: string; // Texto de ajuda
  onlyPositiveNumber?: boolean;
  onlyInteger?: boolean;
};

const textStyle: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px',
  letterSpacing: '0.15px',
};

export default function InputWithTitle({
  title,
  tooltipText,
  initialAdornment,
  defaultValue,
  inputStyle,
  disabled,
  onChange,
  validator,
  onlyInteger,
  helperText,
  onlyPositiveNumber = true,
}: Readonly<InputWithTitleProps>) {
  const [error, setError] = useState<string | null>(null); // Estado para o erro
  const [actualValue, setActualValue] = useState<string | undefined>(); // Estado para o valor atual
  const [lastestValue, setLastestValue] = useState<string | undefined>();
  const [wasEdited, setWasEdited] = useState<boolean>(false);

  useEffect(() => {
    const isError = validator?.(actualValue || defaultValue || '');
    if (isError) setError(isError);
    else if (error !== null) setError(null);
  }, []);

  useEffect(() => {
    if (disabled) setActualValue(undefined);
    else setActualValue(defaultValue);

    const isError = validator?.(defaultValue || '');
    if (isError) setError(isError);
    else if (error !== null) setError(null);
  }, [disabled]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const normalizedValue = Formarters.parseNumberWithComma(value);

    // Executa a função de validação, se houver
    if (validator) {
      validationError = validator(value);
      setError(validationError); // Atualiza o estado do erro
    }

    if (onlyInteger && value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError) {
        value = parseInt(value).toString();
      }
    }

    if (onlyPositiveNumber) {
      let canUpdateValue = true;
      if (normalizedValue < 0) canUpdateValue = false;
      if (isError) canUpdateValue = false;
      if (value === 'NaN') canUpdateValue = false;

      if (canUpdateValue || value === '') {
        if (onlyInteger) setActualValue(value);
        else setActualValue(Formarters.maskMoneyUnlimited(value));
        onChange?.(event, validationError);
        if (wasEdited === false) {
          setWasEdited(true);
        }
      }
    } else {
      if (onlyInteger) setActualValue(value);
      else setActualValue(Formarters.maskMoneyUnlimited(value));
      onChange?.(event, validationError);
      if (wasEdited === false) {
        setWasEdited(true);
      }
    }
  };

  return (
    <div>
      <Box display='flex' gap='4px'>
        <Typography style={{ ...textStyle }}>{title}</Typography>
        <HelpTooltip tooltipText={tooltipText} size={12} />
      </Box>
      <Input1
        sx={{ marginTop: '4px', ...inputStyle }}
        onChange={handleInputChange} // Substitui o onChange original
        defaultValue={defaultValue}
        value={actualValue}
        disabled={disabled}
        error={!!error} // Se houver erro, ativa o estado de erro no Input1
        helperText={error || helperText} // Mostra o erro ou o texto de ajuda
        isControlledComponent
        InputProps={{
          startAdornment: <InputAdornment position='start'>{initialAdornment}</InputAdornment>,
        }}
        onFocus={() => {
          setLastestValue(actualValue);
          setActualValue(''); // Limpa o valor ao focar
        }}
        onBlur={(e) => {
          const v = e.target.value;
          if (!v) {
            if (wasEdited) setActualValue('0');
            else setActualValue(lastestValue); // Restaura o valor padrão se o campo estiver vazio
          }
          setWasEdited(false);
        }}
      />
    </div>
  );
}
