/* eslint-disable no-lonely-if */
import { CronometroDTO } from '../dto/gameDetailsDTO';
import { searchCustPriceByPaymentTerm } from '../pages/MainPage/MidContainer/MidContainerContent/util';
import { Formarters } from './formaters';

const cache: { [key: string]: number | string } = {};

export function scaleValue(scale: number, value: number | string, factorProportion = 1): number | string {
  const cacheKey = `${scale}_${value}`;

  const adjustedScale = factorProportion === 1 ? scale : 1 - (1 - scale) * factorProportion;
  // Verifica se o resultado já está em cache
  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  let result: number | string;

  if (typeof value === 'number') {
    result = value * adjustedScale;
  } else if (typeof value === 'string' && value.includes('px')) {
    result = `${parseFloat(value) * adjustedScale}px`;
  } else {
    result = value;
  }

  // Armazena o resultado no cache para reutilização futura
  cache[cacheKey] = result;

  return result;
}

// Transforma tudo que vem divido por ponto num novo objeto como sua respectiva key
// Exemplo:
// {'rh.vendedores.quantidades': 2, 'compras.A.prazo': '1+1', etc} -> {rh: {vendedores: {quantidades: 2}}, compras: {A:{prazo: '1+1'}}}
export function transformToNestedObject(input: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  Object.keys(input).forEach((key) => {
    const keys = key.split('.'); // Divide a chave pelo '.'
    let currentLevel = result;

    keys.forEach((part, index) => {
      // Se for o último nível, define o valor
      if (index === keys.length - 1) {
        currentLevel[part] = input[key];
      } else {
        // Se não existir, cria um novo objeto
        if (!currentLevel[part]) {
          currentLevel[part] = {};
        }
        currentLevel = currentLevel[part];
      }
    });
  });

  return result;
}

export function getDataFromLocalStorageOrFallback<T>(localStorageData: any, fallbackData: T, keys: string[]): Record<string, any> {
  return keys.reduce((acc, key) => {
    const localStorageValue = key.split('.').reduce((obj: any, k) => obj?.[k], localStorageData);
    const fallbackValue = key.split('.').reduce((obj: any, k) => obj?.[k], fallbackData);

    // Se ambos localStorageValue e fallbackValue são undefined, retorna 0
    const finalValue = localStorageValue ?? fallbackValue ?? 0;

    // Monta o objeto de forma aninhada
    const keyParts = key.split('.');
    let current = acc;

    keyParts.forEach((part, index) => {
      if (index === keyParts.length - 1) {
        current[part] = finalValue;
      } else {
        // Assegura que a parte intermediária é um objeto
        current[part] = current[part] || {};
        current = current[part];
      }
    });

    return acc;
  }, {} as Record<string, any>);
}

export function transformStringsToNumbers(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    // Verifica se o valor é uma string e se pode ser convertido para número
    if (typeof value === 'string' && !Number.isNaN(Formarters.parseNumberWithComma(value))) {
      result[key] = Formarters.parseNumberWithComma(value); // Converte para número
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // Recursivamente aplica a transformação para objetos aninhados
      result[key] = transformStringsToNumbers(value);
    } else {
      // Mantém o valor original se não for uma string ou se não puder ser convertido
      result[key] = value;
    }
  });

  return result;
}

export function calculateMinutesUntilDate(cronometro: CronometroDTO): number {
  if (cronometro.tipo === 'expirada') return 0;
  const { milliseconds, seconds, minutes, hours, days, months, years } = cronometro.difference;

  // Converte os componentes em minutos
  const totalMinutes =
    minutes +
    seconds / 60 + // Converte os segundos para fração de minutos
    milliseconds / (1000 * 60) + // Converte os milissegundos para fração de minutos
    hours * 60 +
    days * 24 * 60 +
    months * 30 * 24 * 60 + // Aproximando meses com 30 dias
    years * 12 * 30 * 24 * 60; // Aproximando anos com 12 meses de 30 dias

  return totalMinutes < 0 ? 0 : totalMinutes;
}

export const objectHasChanges = (objOriginal: any, changes: any): boolean => {
  let changed = false;

  // Função auxiliar para verificar recursivamente se houve mudanças
  const checkChanges = (original: any, updated: any): any => {
    // Itera sobre as chaves do objeto de mudanças
    Object.keys(updated).forEach((key) => {
      // Verifica se o valor é um objeto e não um array ou null
      if (typeof updated[key] === 'object' && !Array.isArray(updated[key]) && updated[key] !== null) {
        // Se o original for também um objeto, faz a verificação recursiva
        if (typeof original[key] === 'object' && original[key] !== null) {
          if (checkChanges(original[key], updated[key])) {
            changed = true;
          }
        } else {
          // Se o original não for um objeto, houve mudança
          changed = true;
        }
      } else {
        // Se os valores forem diferentes, houve mudança
        if (original[key] !== updated[key]) {
          changed = true;
        }
      }
    });
  };

  // Inicia a verificação de mudanças
  checkChanges(objOriginal, changes);

  return changed;
};

export function isEdgeBrowser(): boolean {
  return /Edg/.test(navigator.userAgent);
}

export function roundNumbersInObject(obj: any): any {
  // Função auxiliar para verificar se é um número ou uma string numérica
  const isNumeric = (value: any) => !Number.isNaN(parseFloat(value)) && Number.isFinite(value);

  // Função auxiliar para arredondar para 2 casas decimais
  const roundToTwoDecimals = (num: number) => Math.floor(num * 100) / 100;

  const recursiveRound = (input: any): any => {
    if (typeof input === 'object' && input !== null) {
      // Se for um objeto ou array, percorra suas propriedades ou índices
      Object.keys(input).forEach((key) => {
        input[key] = recursiveRound(input[key]);
      });
    } else if (isNumeric(input)) {
      // Se for numérico, faça o arredondamento e converta de volta para string, se necessário
      const number = parseFloat(input);
      return roundToTwoDecimals(number);
    }
    return input; // Retorna o valor original se não for numérico
  };

  // Chama a função recursiva com o objeto original
  return recursiveRound(obj);
}

export function verifyDataToSend(validators: any, data: any, dataEtapa: any): any {
  const fieldsWithError: string[] = [];
  const rhKeys: string[] = ['vendedores', 'operacionais'];
  const contasKeys: string[] = ['aplicacao_1', 'aplicacao_2'];
  const productsKeys: string[] = ['A', 'B', 'C', 'D', 'E'];
  const sectionsKeys: string[] = ['rh', 'contas_pagar', 'financas', 'compras', 'vendas'];
  const sectionsWhitError: string[] = [];

  // Verificando campos RH
  rhKeys.forEach((key) => {
    if (data.rh[key].salarios < validators[`salario_${key}_maximo`]) {
      fieldsWithError.push(`rh.${key}.salarios`);
    }

    if (data.rh[key].demitidos > validators[`demitidos_${key}_maximo`]) {
      fieldsWithError.push(`rh.${key}.demitidos`);
    }

    if (data.rh[key].admitidos > 99) {
      fieldsWithError.push(`rh.${key}.admitidos`);
    }

    if (data.rh[key].hora_extra > 25) {
      fieldsWithError.push(`rh.${key}.hora_extra`);
    }
  });

  // Verificando campos CONTAS_PAGAR
  if (data.contas_pagar.antecipacao_pgtos > validators.antecipacao_pagamentos_maximo) {
    fieldsWithError.push('contas_pagar.antecipacao_pgtos');
  }

  if (data.contas_pagar.desconto_duplicatas > validators.desconto_duplicatas_maximo) {
    fieldsWithError.push('contas_pagar.desconto_duplicatas');
  }

  if (data.contas_pagar.ampliacao_m2 > validators.ampliacao_maximo) {
    fieldsWithError.push('contas_pagar.ampliacao_m2');
  }

  // Verificando campos FINANCAS
  contasKeys.forEach((key) => {
    if (data.financas[key].deposito > validators[key].deposito_maximo) {
      fieldsWithError.push(`financas.${key}.deposito`);
    }
  });

  if (data.financas.aplicacao_1.saque > validators.saque_maximo) {
    fieldsWithError.push('financas.aplicacao_1.saque');
  }

  if (data.financas.emprestimo_normal.valor > validators.emprestimo_maximo) {
    fieldsWithError.push('financas.emprestimo_normal.valor');
  }

  // Verificando campos PRODUTOS

  productsKeys.forEach((key) => {
    const mediumCust = searchCustPriceByPaymentTerm(data.compras, dataEtapa, key);
    const minimumCust = validators.produtos[key].preco_venda_minimo;
    const minimumValidator = mediumCust > minimumCust ? mediumCust : minimumCust;

    if (
      (data.compras[key].prazo_pagamento !== 0 && data.compras[key].qtde_compra === 0) ||
      data.compras[key].qtde_compra > validators.produtos[key].qtd_compra_maximo ||
      typeof data.compras[key].qtde_compra !== 'number' || // Verifica se não é um número
      !Number.isInteger(data.compras[key].qtde_compra) // Verifica se não é um inteiro
    ) {
      fieldsWithError.push(`compras.${key}.qtde_compra`);
    }

    if (data.vendas[key].condicao_recebimento !== 0 && data.vendas[key].tx_financiamento_vendedor_porc > 25) {
      fieldsWithError.push(`vendas.${key}.tx_financiamento_vendedor_porc`);
    }

    if (
      (data.vendas[key].condicao_recebimento !== 0 && data.vendas[key].preco_venda > validators.produtos[key].preco_venda_maximo) ||
      (data.vendas[key].condicao_recebimento !== 0 && data.vendas[key].preco_venda < minimumValidator)
    ) {
      fieldsWithError.push(`vendas.${key}.preco_venda`);
    }
  });

  sectionsKeys.forEach((element) => {
    fieldsWithError.forEach((it) => {
      if (it.includes(element)) {
        sectionsWhitError.push(Formarters.translateDecisionSectionKey(element));
      }
    });
  });

  localStorage.setItem('sectionsWhitError', JSON.stringify(sectionsWhitError));
  return fieldsWithError;
}
