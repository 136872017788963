import { Box, Typography } from '@mui/material';
import React from 'react';
import { Box1, InputWithTitle, SelectWithTitle } from '../../../index';
import { scaleValue } from '../../../utils'; // Função scaleValue

export type DropdownSelectOption = {
  label: string;
  value: string;
};

export type Row = {
  valueInputOne?: string | DropdownSelectOption[];
  valueInputTwo?: string | DropdownSelectOption[];
  valueInputThree?: string | DropdownSelectOption[];
};

export type ProductStockContainerProps = {
  rowOne: Row;
  rowTwo: Row;
  rowThree: Row;
  rowFour: Row;
  productName: string;
  subProductName: string;
  stockQuantity: string | number;
  onChange: (data: any) => void;
  scale?: number; // Adicionando uma prop scale para personalizar a escala
};

export default function ProductStockContainer({
  productName,
  subProductName,
  rowOne,
  rowTwo,
  rowThree,
  rowFour,
  stockQuantity,
  onChange,
  scale = 1,
}: ProductStockContainerProps) {
  const values: any = {};

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    values[event.target.id] = event.target.value;
    onChange(values);
  };

  function buildHeader(): JSX.Element {
    return (
      <Box display='flex' justifyContent='space-between' sx={{ width: '100%' }}>
        <Box>
          <Typography
            sx={{
              width: '100%',
              color: 'black',
              fontSize: scaleValue(scale, 36),
              fontFamily: 'Poppins',
              fontWeight: '600',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {productName}
          </Typography>
          <Typography
            sx={{
              width: '100%',
              color: 'rgba(0, 0, 0, 0.60)',
              fontSize: scaleValue(scale, 12),
              fontFamily: 'Poppins',
              fontWeight: '400',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {subProductName}
          </Typography>
        </Box>
        <Box display='flex' sx={{ alignItems: 'center', gap: scaleValue(scale, '4px') }}>
          <Typography
            sx={{
              textAlign: 'right',
              color: 'rgba(0, 0, 0, 0.60)',
              fontSize: scaleValue(scale, 14),
              fontFamily: 'Poppins',
              fontWeight: '400',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Quantidade:
          </Typography>
          <Typography
            sx={{
              color: 'black',
              fontSize: scaleValue(scale, 16),
              fontFamily: 'Poppins',
              fontWeight: '600',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {stockQuantity}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{
        height: 'auto',
        width: '85%',
        padding: scaleValue(scale, '23px 40px'),
      }}
    >
      {buildHeader()}
      <Box display='flex' flexDirection='column' gap={scaleValue(scale, '32px')} marginTop={scaleValue(scale, '12px')}>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: '100%',
            columnGap: scaleValue(scale, '18px'),
            rowGap: scaleValue(scale, '36px'),
          }}
        >
          <Typography
            sx={{
              width: '100%',
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Informações
          </Typography>
          <Box display='flex' sx={{ justifyContent: 'space-between' }} gap={scaleValue(scale, '32px')} flexWrap='wrap'>
            <InputWithTitle
              disabled
              title='Estoque Inicial'
              tooltipText='É o estoque do produto quando começou a etapa anterior'
              onChange={handleChangeInput}
              defaultValue={rowOne.valueInputOne as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
            <InputWithTitle
              disabled
              title='(+)Entradas'
              tooltipText='É a quantidade de produto recebida pelo fornecedor na etapa anterior'
              onChange={handleChangeInput}
              defaultValue={rowOne.valueInputTwo as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
            <InputWithTitle
              disabled
              title='(-)Saídas/Vendas'
              tooltipText='É a quantidade produto vendida na etapa anterior'
              onChange={handleChangeInput}
              defaultValue={rowOne.valueInputThree as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
          </Box>
          <Box display='flex' sx={{ justifyContent: 'space-between' }} gap={scaleValue(scale, '32px')} flexWrap='wrap'>
            <InputWithTitle
              disabled
              title='(-)Perdas'
              tooltipText='É a quantidade de produto que sobrou no estoque e venceu na etapa anterior'
              onChange={handleChangeInput}
              defaultValue={rowTwo.valueInputOne as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
            <InputWithTitle
              disabled
              title='(=)Estoque Final'
              tooltipText='É o estoque da etapa atual'
              onChange={handleChangeInput}
              defaultValue={rowTwo.valueInputTwo as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
            <InputWithTitle
              disabled
              title='(Est. em trânsito)'
              tooltipText='É o estoque comprado pelo fornecedor na etapa anterior que esta a chegar para a próxima etapa'
              onChange={handleChangeInput}
              defaultValue={rowTwo.valueInputThree as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
          </Box>
          <Box display='flex' sx={{ justifyContent: 'space-between' }} gap={scaleValue(scale, '32px')} flexWrap='wrap'>
            <InputWithTitle
              disabled
              title='Custo Médio'
              tooltipText='Valor médio de aquisição do produto, calculado com base no estoque final'
              initialAdornment='$'
              onChange={handleChangeInput}
              defaultValue={rowThree.valueInputOne as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
            <InputWithTitle
              disabled
              title='Saldo Final'
              tooltipText='Resultado do estoque final multiplicado pelo custo médio'
              initialAdornment='$'
              onChange={handleChangeInput}
              defaultValue={rowThree.valueInputTwo as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
            <InputWithTitle
              disabled
              title='Quant. não Atendida'
              tooltipText='Quantidades de produtos procurados pelos cilientes e não encontradas no estoque'
              onChange={handleChangeInput}
              defaultValue={rowThree.valueInputThree as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
          </Box>
          <Box display='flex' sx={{ justifyContent: 'space-between' }} gap={scaleValue(scale, '32px')}>
            <InputWithTitle
              disabled
              title='Multa por não atendimento'
              tooltipText='Multa aplicada pelo orgão de defesa do consumidor pela quantidade de produtos não atendidas'
              initialAdornment='$'
              onChange={handleChangeInput}
              defaultValue={rowFour.valueInputOne as string | undefined}
              inputStyle={{ pointerEvents: 'none' }}
            />
          </Box>
        </Box>
      </Box>
    </Box1>
  );
}
