/* eslint-disable consistent-return */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box1, Input1, HelpTooltip, DropdownSelect } from '../../../index';
import { scaleValue, Formarters } from '../../../utils';

export type DropdownSelectOption = {
  label: string;
  value: string;
  key?: string;
  defaultValue?: DropdownSelectOption;
};

export type RowInputProp = {
  key: string;
  value: string;
  helperText?: string;
  disabled?: boolean;
  validator?: (value: string) => string | null;
};

export type Row = {
  valueInputOne?: RowInputProp | DropdownSelectOption[];
  valueInputTwo?: RowInputProp | DropdownSelectOption[];
};

export type RhContainerProps = {
  rowOne: Row;
  rowTwo: Row;
  rowThree: Row;
  rowFour: Row;
  rowFive: Row;
  rowSix: Row;
  rowSeven: Row;
  isTimeOver?: boolean;
  onChange: (data: any, error: string | null) => void;
  scale?: number; // Adicionando a prop scale
  quantityOperacional?: number;
  quantityVendedor?: number;
};

const helpTextMap: Record<string, Record<string, string>> = {
  operator: {
    admitidos: 'Operacionais: aumento do número de contratação de operacionais. Auxilia no MRH-CO.',
    demitidos: 'Operacionais: redução da força de trabalho dos operacionais.',
    salario: 'Operacionais: define-se o salário dos operacionais.',
    horaExtra: 'Operacionais: define-se o valor entre 0 e 25%.',
    treinamento: 'Operacionais: define-se o valor entre 0 e 9.',
    participacao: 'Operacionais: participação nos lucros após os impostos, de 0 a 9.',
  },
  sales: {
    admitidos: 'Vendedores: aumento do número de contratação de vendedores. Auxilia no MRH-CV.',
    demitidos: 'Vendedores: redução da força de trabalho dos vendedores.',
    salario: 'Vendedores: define-se o salário dos vendedores.',
    horaExtra: 'Vendedores: define-se o valor entre 0 e 25%.',
    treinamento: 'Vendedores: define-se o valor entre 0 e 9.',
    comissao: 'Vendedores: indica o percentual (%) sobre as vendas brutas.',
  },
};

function buildTooltipText(paramHasSales: boolean, paramHasOperators: boolean, tooltipHelpTextKey: string) {
  let text = '';
  if (paramHasOperators && !paramHasSales && Object.hasOwn(helpTextMap.operator, tooltipHelpTextKey)) {
    text += helpTextMap.operator[tooltipHelpTextKey];
  }
  if (paramHasSales && !paramHasOperators && Object.hasOwn(helpTextMap.sales, tooltipHelpTextKey)) {
    text += helpTextMap.sales[tooltipHelpTextKey];
  }
  if (
    paramHasSales &&
    paramHasOperators &&
    Object.hasOwn(helpTextMap.operator, tooltipHelpTextKey) &&
    Object.hasOwn(helpTextMap.sales, tooltipHelpTextKey)
  ) {
    text += `${helpTextMap.operator[tooltipHelpTextKey]}\n\n${helpTextMap.sales[tooltipHelpTextKey]}`;
  }
  return text;
}

const textStyle: any = (scale: number) => ({
  color: 'black',
  fontSize: scaleValue(scale, 22),
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: 0.15,
  wordWrap: 'break-word',
});

function notHasEmployees(scale: number) {
  return (
    <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
      Não Possui
    </Typography>
  );
}

function RowContainer({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
  isSalaryRow = false,
}: Readonly<{
  title: string;
  hasOperators?: boolean;
  hasSales?: boolean;
  rowValue: Row;
  onChangeInputOne: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, error: string | null) => void;
  onChangeInputTwo: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, error: string | null) => void;
  scale?: number;
  tooltipHelpTextKey: string;
  isSalaryRow?: boolean;
}>) {
  const [errorOne, setErrorOne] = useState<string | null>(null); // Estado para o erro do primeiro input
  const [errorTwo, setErrorTwo] = useState<string | null>(null); // Estado para o erro do segundo input
  const [actualValueOne, setActualValueOne] = useState<string | undefined>(undefined); // Estado para o valor atual
  const [actualValueTwo, setActualValueTwo] = useState<string | undefined>(undefined); // Estado para o valor atual
  const [lastestValueOne, setLastestValueOne] = useState<string | undefined>();
  const [lastestValueTwo, setLastestValueTwo] = useState<string | undefined>();
  const [wasEdited, setWasEdited] = useState<boolean>(false);

  useEffect(() => {
    if ((rowValue.valueInputOne as RowInputProp).validator) {
      const isError = (rowValue.valueInputOne as RowInputProp).validator?.((rowValue.valueInputOne as RowInputProp).value || '');
      if (isError) setErrorOne(isError);
    }
    if ((rowValue.valueInputTwo as RowInputProp).validator) {
      const isError = (rowValue.valueInputTwo as RowInputProp).validator?.((rowValue.valueInputTwo as RowInputProp).value || '');
      if (isError) setErrorTwo(isError);
    }
  }, [rowValue]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setActualValue: React.Dispatch<React.SetStateAction<string | undefined>>,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    onChangeEvent: (eventP: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, error: string | null) => void,
    validator?: (value: string) => string | null,
  ) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const normalizedValue = Formarters.parseNumberWithComma(value);

    if (validator) {
      validationError = validator(value) ?? null;
      setError(validationError); // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError && !isSalaryRow) {
        value = parseInt(value).toString();
      }
    }

    if (normalizedValue >= 0 || value === '') {
      if (!isError || isSalaryRow) {
        if (value !== 'NaN') {
          const finalValue = isSalaryRow ? Formarters.maskMoneyUnlimited(value) : value;
          onChangeEvent(event, validationError);
          setActualValue(finalValue);
          if (wasEdited === false) {
            setWasEdited(true);
          }
          return event;
        }
      }
    }
  };

  const tooltipText = buildTooltipText(hasSales, hasOperators, tooltipHelpTextKey);

  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={tooltipText} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <Input1
            defaultValue={(rowValue.valueInputOne as RowInputProp).value}
            value={actualValueOne}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={(e) =>
              handleInputChange(e, setActualValueOne, setErrorOne, onChangeInputOne, (rowValue.valueInputOne as RowInputProp).validator)
            }
            error={!!errorOne}
            helperText={errorOne || (rowValue.valueInputOne as RowInputProp).helperText}
            disabled={(rowValue.valueInputOne as RowInputProp).disabled}
            isControlledComponent
            onFocus={() => {
              setLastestValueOne(actualValueOne);
              setActualValueOne(''); // Limpa o valor ao focar
            }}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v) {
                if (wasEdited) setActualValueOne('0');
                else setActualValueOne(lastestValueOne); // Restaura o valor padrão se o campo estiver vazio
              }
              setWasEdited(false);
            }}
          />
        ) : (
          <Typography align='right' color='gray' variant='h6'>
            Não Possui
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <Input1
            defaultValue={(rowValue.valueInputTwo as RowInputProp).value}
            value={actualValueTwo}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={(e) =>
              handleInputChange(e, setActualValueTwo, setErrorTwo, onChangeInputTwo, (rowValue.valueInputTwo as RowInputProp).validator)
            }
            error={!!errorTwo}
            helperText={errorTwo ?? (rowValue.valueInputTwo as RowInputProp).helperText}
            disabled={(rowValue.valueInputTwo as RowInputProp).disabled}
            isControlledComponent
            onFocus={() => {
              setLastestValueTwo(actualValueTwo);
              setActualValueTwo(''); // Limpa o valor ao focar
            }}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v) {
                if (wasEdited) setActualValueTwo('0');
                else setActualValueTwo(lastestValueTwo); // Restaura o valor padrão se o campo estiver vazio
              }
              setWasEdited(false);
            }}
          />
        ) : (
          <Typography align='right' color='gray' variant='h6'>
            Não Possui
          </Typography>
        )}
      </Grid>
    </>
  );
}

function DropdownSelectBoxState({
  scale,
  options,
  dropdownValueParam,
  onSelect,
  disabled,
}: {
  scale: number;
  options: DropdownSelectOption[];
  dropdownValueParam?: DropdownSelectOption;
  onSelect: (option: DropdownSelectOption) => void;
  disabled?: boolean;
}) {
  const [dropdownValue, setDropdownValue] = useState<DropdownSelectOption | undefined>(dropdownValueParam);

  const handleChangeSelect = (option: DropdownSelectOption) => {
    setDropdownValue(option);
    onSelect(option);
  };

  return (
    <DropdownSelect
      boxStyle={{
        marginTop: scaleValue(scale, '4px'),
        marginLeft: 'auto',
        width: '100%',
        boxSizing: 'border-box',
      }}
      label=''
      options={options}
      dropdownValue={dropdownValue}
      onSelect={handleChangeSelect}
      disabled={disabled}
    />
  );
}

function RowContainerSelect({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
  isTimeOver,
}: {
  title: string;
  hasOperators?: boolean;
  hasSales?: boolean;
  rowValue: Row;
  onChangeInputOne: (option: DropdownSelectOption) => void;
  onChangeInputTwo: (option: DropdownSelectOption) => void;
  tooltipHelpTextKey: string;
  scale?: number;
  isTimeOver?: boolean;
}) {
  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={buildTooltipText(hasSales, hasOperators, tooltipHelpTextKey)} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <DropdownSelectBoxState
            scale={scale}
            options={rowValue.valueInputOne as DropdownSelectOption[]}
            dropdownValueParam={(rowValue.valueInputOne as DropdownSelectOption[])[0].defaultValue}
            onSelect={onChangeInputOne}
            disabled={isTimeOver}
          />
        ) : (
          notHasEmployees(scale)
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <DropdownSelectBoxState
            scale={scale}
            options={rowValue.valueInputTwo as DropdownSelectOption[]}
            dropdownValueParam={(rowValue.valueInputTwo as DropdownSelectOption[])[0].defaultValue}
            onSelect={onChangeInputTwo}
            disabled={isTimeOver}
          />
        ) : (
          notHasEmployees(scale)
        )}
      </Grid>
    </>
  );
}

export default function RhContainer({
  rowOne,
  rowTwo,
  rowThree,
  rowFour,
  rowFive,
  rowSix,
  rowSeven,
  onChange,
  scale = 1,
  isTimeOver,
  quantityOperacional,
  quantityVendedor,
}: Readonly<RhContainerProps>) {
  const values: any = {};

  const handleChangeInput = (key: string, value: string, error: string | null) => {
    values[key] = value;
    onChange(values, error);
  };

  const handleChangeSelect = (option: DropdownSelectOption) => {
    values[option.key ?? option.label] = option.value;
    onChange(values, null);
  };

  return (
    <Box1
      sx={{
        height: 'auto',
        width: '95%',
        boxSizing: 'border-box',
        padding: scaleValue(scale, '23px 40px'),
      }}
    >
      <Grid container spacing={scaleValue(scale, 2)}>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
            Operacionais{quantityOperacional ? ` (Atual: ${quantityOperacional})` : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
            Vendedores{quantityVendedor ? ` (Atual: ${quantityVendedor})` : ''}
          </Typography>
        </Grid>
        <RowContainer
          title='Admitidos'
          rowValue={rowOne}
          onChangeInputOne={(event, error) => handleChangeInput((rowOne.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowOne.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='admitidos'
          scale={scale}
        />
        <RowContainer
          title='Demitidos'
          rowValue={rowTwo}
          onChangeInputOne={(event, error) => handleChangeInput((rowTwo.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowTwo.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='demitidos'
          scale={scale}
        />
        <RowContainer
          isSalaryRow
          title='Salários($)'
          rowValue={rowThree}
          onChangeInputOne={(event, error) => handleChangeInput((rowThree.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowThree.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='salario'
          scale={scale}
        />
        <RowContainerSelect
          title='Comissão'
          hasOperators={false}
          rowValue={rowFour}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='comissao'
          scale={scale}
          isTimeOver={isTimeOver}
        />
        <RowContainer
          title='Hora Extra'
          rowValue={rowFive}
          onChangeInputOne={(event, error) => handleChangeInput((rowFive.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowFive.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='horaExtra'
          scale={scale}
        />
        <RowContainerSelect
          title='Treinamento'
          rowValue={rowSix}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='treinamento'
          scale={scale}
          isTimeOver={isTimeOver}
        />
        <RowContainerSelect
          title='Participação'
          hasSales={false}
          rowValue={rowSeven}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='participacao'
          scale={scale}
          isTimeOver={isTimeOver}
        />
      </Grid>
    </Box1>
  );
}
