import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { FolhaDecisaoDTO } from '../../../../dto';
import { EtapaDTO } from '../../../../dto/etapaDTO';
import { GameDetailsDTO } from '../../../../dto/gameDetailsDTO';
import { Formarters } from '../../../../lib/formaters';
import { transformStringsToNumbers } from '../../../../lib/utils';
import { ProductContainer, IconCardList, InvoiceContainer, FinanceContainer, RhContainer } from '../../../../ui/index';
import { searchCustPriceByPaymentTerm, getSellPrice, getDatasFromLocalStorageOrFallback } from './util';

const maskMoneyUnlimited = (value: any) => {
  if (typeof value !== 'string') return value;
  // Remove todos os caracteres que não são dígitos
  value = value.replace(/\D/g, '');

  // Se o valor for menor que 3 dígitos, ajuste para ter pelo menos 3
  // while (value.length < 3) {
  //   value = `0${value}`;
  // }

  // Adiciona a vírgula para os decimais
  value = value.replace(/(\d{2})$/, ',$1');

  // Adiciona o ponto para cada grupo de milhares
  value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  // value = Formarters.parseNumberWithComma(value.replace('.', ','));
  // Adiciona o símbolo de moeda
  return Formarters.parseNumberWithComma(value);
};

const maskMoneyUnlimitedWithoutFormat = (value: any) => {
  // Remove todos os caracteres que não são dígitos
  value = value.replace(/\D/g, '');

  // Se o valor for menor que 3 dígitos, ajuste para ter pelo menos 3
  // while (value.length < 3) {
  //   value = `0${value}`;
  // }

  // Adiciona a vírgula para os decimais
  value = value.replace(/(\d{2})$/, ',$1');

  // Adiciona o ponto para cada grupo de milhares
  value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  // value = Formarters.parseNumberWithComma(value.replace('.', ','));
  // Adiciona o símbolo de moeda
  return value;
};

const checkForError = (icon: any) => {
  const sectionsWithError = JSON.parse(localStorage.getItem('sectionsWhitError') || '[]');
  return sectionsWithError.some((error: string) => icon.label.includes(error));
};

function MidContainerContent({
  scale = 1,
  onChange,
  folhaDecisaoData,
  dataEtapa,
  gameDetailsDTO,
}: {
  scale?: number;
  onChange: (data: any) => void;
  folhaDecisaoData: FolhaDecisaoDTO;
  dataEtapa: EtapaDTO;
  gameDetailsDTO: GameDetailsDTO;
}) {
  const [cardSelected, setCardSelected] = React.useState<string>('Sign');
  const [isTimeOver, setIsTimeOver] = React.useState<boolean>(localStorage.getItem('isTimeOver') === 'true' || false);
  const midContainerDataFromLocalStorage = JSON.parse(sessionStorage.getItem('midContainerData') ?? '{}');
  const { validadores } = folhaDecisaoData;
  const { compras, vendas, financas, contas_pagar, rh, marketing } = getDatasFromLocalStorageOrFallback(
    midContainerDataFromLocalStorage,
    folhaDecisaoData,
  );

  const getBooleanFromLocalStorage = (key: string): boolean => {
    return localStorage.getItem(key) === 'true';
  };

  useEffect(() => {
    const handleChange = () => {
      setIsTimeOver(getBooleanFromLocalStorage('isTimeOver'));
    };

    window.addEventListener('isTimeOverChange', handleChange);

    // Atualiza o estado ao montar o componente
    handleChange();

    return () => {
      window.removeEventListener('isTimeOverChange', handleChange);
    };
  }, []);

  function createProductContainer() {
    const mappedSubProductNameByKey: Record<string, string> = {
      A: gameDetailsDTO.parametros_iniciais.parametros_produto.A.nome,
      B: gameDetailsDTO.parametros_iniciais.parametros_produto.B.nome,
      C: gameDetailsDTO.parametros_iniciais.parametros_produto.C.nome,
      D: gameDetailsDTO.parametros_iniciais.parametros_produto.D.nome,
      E: gameDetailsDTO.parametros_iniciais.parametros_produto.E.nome,
    };

    return Object.keys(folhaDecisaoData.stock_values).map((key) => (
      <ProductContainer
        key={key}
        scale={scale}
        isTimeOver={isTimeOver}
        productName={`Produto ${key}`}
        subProductName={mappedSubProductNameByKey[key]}
        checkboxStatus={vendas[key]?.com_entrada}
        rowOne={{
          valueInputOne: [
            {
              label: 'Não comprar',
              value: '0',
              key: `compras.${key}.prazo_pagamento`,
              defaultValue: {
                label: 'not in use',
                value: compras[key]?.prazo_pagamento,
              },
            },
            {
              label: 'Comprar à vista',
              value: '1',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '1+1',
              value: '2',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '1+2',
              value: '3',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '0+2',
              value: '20',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '0+3',
              value: '30',
              key: `compras.${key}.prazo_pagamento`,
            },
          ],
          valueInputTwo: {
            key: `compras.${key}.preco_custo`,
            disabled: true,
            value: searchCustPriceByPaymentTerm(compras, dataEtapa, key).toFixed(2).toString().replace('.', ','),
          },
          valueInputThree: {
            key: `compras.${key}.qtde_compra`,
            value: compras[key]?.qtde_compra,
            helperText: `Min: 1 / Max: ${validadores.produtos[key]?.qtd_compra_maximo}`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (
                Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.produtos[key]?.qtd_compra_maximo) ||
                (Formarters.parseNumberWithComma(value) < 1 && compras[key].prazo_pagamento !== 0)
              )
                return 'Quantidade de compra fora do intervalo permitido';

              return null;
            },
          },
        }}
        rowTwo={{
          valueInputOne: [
            {
              label: 'Não vender',
              value: '0',
              key: `vendas.${key}.condicao_recebimento`,
              defaultValue: {
                label: 'not in use',
                value: vendas[key]?.condicao_recebimento,
              },
            },
            {
              label: 'À vista',
              value: '1',
              key: `vendas.${key}.condicao_recebimento`,
            },
            {
              label: '2 parcelas',
              value: '2',
              key: `vendas.${key}.condicao_recebimento`,
            },
            {
              label: '3 parcelas',
              value: '3',
              key: `vendas.${key}.condicao_recebimento`,
            },
          ],
          valueInputTwo: {
            key: `vendas.${key}.preco_venda`,
            value: maskMoneyUnlimitedWithoutFormat(vendas[key].preco_venda.toFixed(2).toString()),
            helperText: `Min: $${Formarters.formatHelperText(
              getSellPrice(validadores, key, compras, vendas, dataEtapa),
            )} / Max: $${Formarters.formatHelperText(validadores.produtos[key]?.preco_venda_maximo)}`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) === 0) return null;
              if (
                maskMoneyUnlimited(value) < Formarters.parseNumberWithComma(getSellPrice(validadores, key, compras, vendas, dataEtapa)) ||
                maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.produtos[key]?.preco_venda_maximo)
              )
                return 'Preço de venda fora do intervalo permitido';
              return null;
            },
          },
          valueInputThree: {
            key: `vendas.${key}.tx_financiamento_vendedor_porc`,
            value: maskMoneyUnlimitedWithoutFormat(vendas[key]?.tx_financiamento_vendedor_porc.toFixed(2).toString().replace('.', ',')),
            helperText: `Max: 25%`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > 25) return 'Taxa de financiamento maior que o permitido';
              return null;
            },
          },
        }}
        rowThree={{
          valueInputOne: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.impressos`,
              defaultValue: { label: 'not in use', value: marketing[key]?.impressos },
            },
            { label: '1', value: '1', key: `marketing.${key}.impressos` },
            { label: '2', value: '2', key: `marketing.${key}.impressos` },
            { label: '3', value: '3', key: `marketing.${key}.impressos` },
            { label: '4', value: '4', key: `marketing.${key}.impressos` },
            { label: '5', value: '5', key: `marketing.${key}.impressos` },
            { label: '6', value: '6', key: `marketing.${key}.impressos` },
            { label: '7', value: '7', key: `marketing.${key}.impressos` },
            { label: '8', value: '8', key: `marketing.${key}.impressos` },
            { label: '9', value: '9', key: `marketing.${key}.impressos` },
          ],
          valueInputTwo: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.midias_sociais`,
              defaultValue: { label: 'not in use', value: marketing[key]?.midias_sociais },
            },
            {
              label: '1',
              value: '1',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '2',
              value: '2',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '3',
              value: '3',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '4',
              value: '4',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '5',
              value: '5',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '6',
              value: '6',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '7',
              value: '7',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '8',
              value: '8',
              key: `marketing.${key}.midias_sociais`,
            },
            { label: '9', value: '9', key: `marketing.${key}.midias_sociais` },
          ],
          valueInputThree: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.radio`,
              defaultValue: { label: 'not in use', value: marketing[key]?.radio },
            },
            { label: '1', value: '1', key: `marketing.${key}.radio` },
            { label: '2', value: '2', key: `marketing.${key}.radio` },
            { label: '3', value: '3', key: `marketing.${key}.radio` },
            { label: '4', value: '4', key: `marketing.${key}.radio` },
            { label: '5', value: '5', key: `marketing.${key}.radio` },
            { label: '6', value: '6', key: `marketing.${key}.radio` },
            { label: '7', value: '7', key: `marketing.${key}.radio` },
            { label: '8', value: '8', key: `marketing.${key}.radio` },
            { label: '9', value: '9', key: `marketing.${key}.radio` },
          ],
          valueInputFour: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.tv`,
              defaultValue: { label: 'not in use', value: marketing[key]?.tv },
            },
            { label: '1', value: '1', key: `marketing.${key}.tv` },
            { label: '2', value: '2', key: `marketing.${key}.tv` },
            { label: '3', value: '3', key: `marketing.${key}.tv` },
            { label: '4', value: '4', key: `marketing.${key}.tv` },
            { label: '5', value: '5', key: `marketing.${key}.tv` },
            { label: '6', value: '6', key: `marketing.${key}.tv` },
            { label: '7', value: '7', key: `marketing.${key}.tv` },
            { label: '8', value: '8', key: `marketing.${key}.tv` },
            { label: '9', value: '9', key: `marketing.${key}.tv` },
          ],
        }}
        onChange={function (data: any, error: string | null): void {
          // if (error) return;
          if (data[`compras.${key}.prazo_pagamento`] === '0') {
            data[`compras.${key}.preco_custo`] = 0;
            data[`compras.${key}.qtde_compra`] = 0;
          }
          if (data[`vendas.${key}.condicao_recebimento`] === '0') {
            data[`vendas.${key}.preco_venda`] = 0;
            data[`vendas.${key}.tx_financiamento_vendedor_porc`] = 0;
          }
          if (data[`vendas.${key}.preco_venda`]) {
            data[`vendas.${key}.preco_venda`] = maskMoneyUnlimited(data[`vendas.${key}.preco_venda`]);
          }
          if (data[`vendas.${key}.tx_financiamento_vendedor_porc`]) {
            data[`vendas.${key}.tx_financiamento_vendedor_porc`] = maskMoneyUnlimited(data[`vendas.${key}.tx_financiamento_vendedor_porc`]);
          }

          onChange(transformStringsToNumbers(data));
        }}
      />
    ));
  }

  function buildFinancerContainer() {
    return (
      <FinanceContainer
        scale={scale}
        isTimeOver={isTimeOver}
        rowOne={{
          valueInputOne: {
            key: 'financas.aplicacao_1.deposito',
            value: maskMoneyUnlimitedWithoutFormat(financas.aplicacao_1.deposito.toFixed(2).toString()),
            helperText: `Max: $${Formarters.formatHelperText(validadores.aplicacao_1.deposito_maximo)}`,
            disabled: isTimeOver,
            validator: (value: any) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.aplicacao_1.deposito_maximo))
                return 'Valor de depósito maior que o permitido';
              return null;
            },
          },
          valueInputTwo: {
            key: 'financas.aplicacao_1.saque',
            value: maskMoneyUnlimitedWithoutFormat(financas.aplicacao_1.saque.toFixed(2).toString()),
            helperText: `Saque Disponível: $${Formarters.formatHelperText(validadores.saque_maximo)}`,
            disabled: validadores.saque_maximo === 0 || isTimeOver,
            validator: (value: any) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.saque_maximo))
                return 'Valor de saque maior que o permitido';
              return null;
            },
          },
          valueInputThree: {
            key: '',
            value: '',
            disabled: true,
          },
        }}
        rowTwo={{
          valueInputOne: {
            key: 'financas.aplicacao_2.deposito',
            value: maskMoneyUnlimitedWithoutFormat(financas.aplicacao_2.deposito.toFixed(2).toString()),
            helperText: `Max: $${Formarters.formatHelperText(validadores.aplicacao_2.deposito_maximo)}`,
            disabled: isTimeOver,
            validator: (value: any) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.aplicacao_2.deposito_maximo))
                return 'Valor de depósito maior que o permitido';
              return null;
            },
          },
          valueInputTwo: [
            {
              label: '0',
              value: '0',
              key: 'financas.aplicacao_2.prazo',
              defaultValue: '0',
            },
            {
              label: '2',
              value: '2',
              key: 'financas.aplicacao_2.prazo',
              defaultValue: financas.aplicacao_2.prazo,
            },
            {
              label: '3',
              value: '3',
              key: 'financas.aplicacao_2.prazo',
              defaultValue: financas.aplicacao_2.prazo,
            },
          ],
        }}
        rowThree={{
          valueInputOne: {
            key: 'financas.emprestimo_normal.valor',
            value: maskMoneyUnlimitedWithoutFormat(financas.emprestimo_normal.valor.toFixed(2).toString()),
            helperText: `Max: $${Formarters.formatHelperText(validadores.emprestimo_maximo)}`,
            disabled: isTimeOver,
            validator: (value: any) => {
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.emprestimo_maximo))
                return 'Valor de empréstimo maior que o permitido';
              return null;
            },
          },
          valueInputTwo: [
            {
              label: '0',
              value: '0',
              key: 'financas.emprestimo_normal.prazo',
              defaultValue: '0',
            },
            {
              label: '2',
              value: '2',
              key: 'financas.emprestimo_normal.prazo',
              defaultValue: financas.emprestimo_normal.prazo,
            },
            {
              label: '3',
              value: '3',
              key: 'financas.emprestimo_normal.prazo',
              defaultValue: financas.emprestimo_normal.prazo,
            },
          ],
        }}
        onChange={function (data: any, error: string | null): void {
          // if (error) return;
          const newData = transformStringsToNumbers(data);
          if (newData['financas.aplicacao_2.prazo'] === 0 && newData['financas.aplicacao_2.deposito'] > 0) {
            newData['financas.aplicacao_2.prazo'] = 2;
          }
          if (newData['financas.emprestimo_normal.prazo'] === 0 && newData['financas.emprestimo_normal.valor'] > 0) {
            newData['financas.emprestimo_normal.prazo'] = 2;
          }
          if (newData['financas.aplicacao_1.deposito']) {
            newData['financas.aplicacao_1.deposito'] = maskMoneyUnlimited(data['financas.aplicacao_1.deposito']);
          }
          if (newData['financas.aplicacao_2.deposito']) {
            newData['financas.aplicacao_2.deposito'] = maskMoneyUnlimited(data['financas.aplicacao_2.deposito']);
          }
          if (newData['financas.emprestimo_normal.valor']) {
            newData['financas.emprestimo_normal.valor'] = maskMoneyUnlimited(data['financas.emprestimo_normal.valor']);
          }
          if (newData['financas.aplicacao_1.saque']) {
            newData['financas.aplicacao_1.saque'] = maskMoneyUnlimited(data['financas.aplicacao_1.saque']);
          }

          onChange(transformStringsToNumbers(newData));
        }}
      />
    );
  }

  function buildRhContainer() {
    return (
      <RhContainer
        quantityOperacional={parseInt(folhaDecisaoData.employees.operacional)}
        quantityVendedor={parseInt(folhaDecisaoData.employees.vendedor)}
        scale={scale}
        isTimeOver={isTimeOver}
        rowOne={{
          valueInputOne: {
            key: 'rh.operacionais.admitidos',
            value: rh.operacionais.admitidos,
            helperText: `Max: 99`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) > 99) return 'Admitidos maior que o permitido';
              if (Formarters.parseNumberWithComma(value) < 0) return 'Admitidos menor que o permitido';
              return null;
            },
          },
          valueInputTwo: {
            key: 'rh.vendedores.admitidos',
            value: rh.vendedores.admitidos,
            helperText: `Max: 99`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) > 99) return 'Admitidos maior que o permitido';
              if (Formarters.parseNumberWithComma(value) < 0) return 'Admitidos menor que o permitido';
              return null;
            },
          },
        }}
        rowTwo={{
          valueInputOne: {
            key: 'rh.operacionais.demitidos',
            value: rh.operacionais.demitidos,
            helperText: `Max: ${validadores.demitidos_operacionais_maximo}`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.demitidos_operacionais_maximo))
                return 'Demissões maior que o permitido';
              return null;
            },
          },
          valueInputTwo: {
            key: 'rh.vendedores.demitidos',
            value: rh.vendedores.demitidos,
            helperText: `Max: ${validadores.demitidos_vendedores_maximo}`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.demitidos_vendedores_maximo))
                return 'Demissões maior que o permitido';
              return null;
            },
          },
        }}
        rowThree={{
          valueInputOne: {
            key: 'rh.operacionais.salarios',
            value: maskMoneyUnlimitedWithoutFormat(rh.operacionais.salarios.toFixed(2).toString()),
            helperText: `Min: $${Formarters.formatHelperText(validadores.salario_operacionais_maximo)}`, // salario_operacionais_maximo == minimo (erro label api)
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) < Formarters.parseNumberWithComma(validadores.salario_operacionais_maximo))
                return 'Salário menor que o permitido';
              return null;
            },
          },
          valueInputTwo: {
            key: 'rh.vendedores.salarios',
            value: maskMoneyUnlimitedWithoutFormat(rh.vendedores.salarios.toFixed(2).toString()),
            helperText: `Min: $${Formarters.formatHelperText(validadores.salario_vendedores_maximo)}`, // salario_operacionais_maximo == minimo (erro label api)
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) < Formarters.parseNumberWithComma(validadores.salario_vendedores_maximo))
                return 'Salário menor que o permitido';
              return null;
            },
          },
        }}
        rowFour={{
          valueInputTwo: [
            {
              label: '0',
              value: '0',
              key: 'rh.vendedores.comissao',
              defaultValue: { label: rh.vendedores.comissao, value: rh.vendedores.comissao },
            },
            { label: '1', value: '1', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
            { label: '2', value: '2', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
            { label: '3', value: '3', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
            { label: '4', value: '4', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
          ],
        }}
        rowFive={{
          valueInputOne: {
            key: 'rh.operacionais.hora_extra',
            value: rh.operacionais.hora_extra,
            helperText: `Max: 25%`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) > 25) return 'Horas extras maior que o permitido';
              return null;
            },
          },
          valueInputTwo: {
            key: 'rh.vendedores.hora_extra',
            value: rh.vendedores.hora_extra,
            helperText: `Max: 25%`,
            disabled: isTimeOver,
            validator: (value) => {
              if (value === '') return null;
              if (Formarters.parseNumberWithComma(value) > 25) return 'Horas extras maior que o permitido';
              return null;
            },
          },
        }}
        rowSix={{
          valueInputOne: [
            {
              label: '0',
              value: '0',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '1',
              value: '1',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '2',
              value: '2',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '3',
              value: '3',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '4',
              value: '4',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '5',
              value: '5',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '6',
              value: '6',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '7',
              value: '7',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '8',
              value: '8',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
            {
              label: '9',
              value: '9',
              key: 'rh.operacionais.treinamento',
              defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
            },
          ],
          valueInputTwo: [
            {
              label: '0',
              value: '0',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '1',
              value: '1',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '2',
              value: '2',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '3',
              value: '3',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '4',
              value: '4',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '5',
              value: '5',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '6',
              value: '6',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '7',
              value: '7',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '8',
              value: '8',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
            {
              label: '9',
              value: '9',
              key: 'rh.vendedores.treinamento',
              defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
            },
          ],
        }}
        rowSeven={{
          valueInputOne: [
            {
              label: '0',
              value: '0',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '1',
              value: '1',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '2',
              value: '2',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '3',
              value: '3',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '4',
              value: '4',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '5',
              value: '5',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '6',
              value: '6',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '7',
              value: '7',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '8',
              value: '8',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
            {
              label: '9',
              value: '9',
              key: 'rh.operacionais.participacao',
              defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
            },
          ],
        }}
        onChange={function (data: any, error: string | null): void {
          // if (error) return;
          if (data['rh.operacionais.salarios']) {
            data['rh.operacionais.salarios'] = maskMoneyUnlimited(data['rh.operacionais.salarios']);
          }
          if (data['rh.vendedores.salarios']) {
            data['rh.vendedores.salarios'] = maskMoneyUnlimited(data['rh.vendedores.salarios']);
          }

          onChange(transformStringsToNumbers(data));
        }}
      />
    );
  }

  function buildInvoiceContainer() {
    // começar por aqui
    return (
      <InvoiceContainer
        scale={scale}
        rowOne={{
          valueInputOne: {
            key: 'contas_pagar.desconto_duplicatas',
            value: maskMoneyUnlimitedWithoutFormat(contas_pagar.desconto_duplicatas.toFixed(2).toString()),
            disabled: isTimeOver,
            helperText: `Max: $${Formarters.formatHelperText(validadores.desconto_duplicatas_maximo)}`,
            validator: (value) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.desconto_duplicatas_maximo))
                return 'Valor de desconto maior que o permitido';
              return null;
            },
          },
        }}
        rowTwo={{
          valueInputOne: {
            key: 'contas_pagar.antecipacao_pgtos',
            value: maskMoneyUnlimitedWithoutFormat(contas_pagar.antecipacao_pgtos.toFixed(2).toString()),
            disabled: isTimeOver,
            helperText: `Max: $${Formarters.formatHelperText(validadores.antecipacao_pagamentos_maximo)}`,
            validator: (value) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.antecipacao_pagamentos_maximo))
                return 'Valor de antecipação maior que o permitido';
              return null;
            },
          },
        }}
        rowThree={{
          valueInputOne: {
            key: 'contas_pagar.ampliacao_m2',
            value: maskMoneyUnlimitedWithoutFormat(contas_pagar.ampliacao_m2.toFixed(2).toString()),
            disabled: isTimeOver,
            helperText: `Max: ${Formarters.formatHelperText(validadores.ampliacao_maximo)}`,
            validator: (value) => {
              if (value === '') return null;
              if (maskMoneyUnlimited(value) > Formarters.parseNumberWithComma(validadores.ampliacao_maximo))
                return 'Valor de ampliação maior que o permitido';
              return null;
            },
          },
        }}
        rowFour={{
          valueInputOne: {
            key: 'contas_pagar.diversos',
            value: maskMoneyUnlimitedWithoutFormat(contas_pagar.diversos.toFixed(2).toString()),
            disabled: true,
          },
        }}
        onChange={function (data: any, error: string | null): void {
          // if (error) return;
          if (data['contas_pagar.desconto_duplicatas']) {
            data['contas_pagar.desconto_duplicatas'] = maskMoneyUnlimited(data['contas_pagar.desconto_duplicatas']);
          }
          if (data['contas_pagar.antecipacao_pgtos']) {
            data['contas_pagar.antecipacao_pgtos'] = maskMoneyUnlimited(data['contas_pagar.antecipacao_pgtos']);
          }
          if (data['contas_pagar.ampliacao_m2']) {
            data['contas_pagar.ampliacao_m2'] = maskMoneyUnlimited(data['contas_pagar.ampliacao_m2']);
          }
          onChange(transformStringsToNumbers(data));
        }}
      />
    );
  }

  return (
    <Box>
      <div style={{ marginBottom: '20px' }}>
        <IconCardList
          onChange={(icon) => setCardSelected(icon.name)}
          hasError={checkForError}
          initialSelected='Sign'
          icons={[
            { name: 'Sign', label: 'Compra/Venda Marketing' },
            { name: 'InvoiceTip', label: 'Finanças' },
            { name: 'StackedBarUp', label: 'Contas' },
            { name: 'RhIcon', label: 'RH' },
          ]}
        />
      </div>
      {cardSelected === 'Sign' && (
        <Box display='flex' flexDirection='column' gap='32px'>
          {createProductContainer()}
        </Box>
      )}
      {cardSelected === 'InvoiceTip' && buildFinancerContainer()}
      {cardSelected === 'StackedBarUp' && buildInvoiceContainer()}
      {cardSelected === 'RhIcon' && buildRhContainer()}
    </Box>
  );
}

export default MidContainerContent;
