import { Box, Typography } from '@mui/material';
import React from 'react';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { DropdownSelect, DropdownSelectOption } from '../../Atomos/DropdownSelect';

export type SelectWithTitleProps = {
  title: string;
  tooltipText: string;
  divStyle?: React.CSSProperties;
  disabled?: boolean;
  options: DropdownSelectOption[];
  onSelect: (option: DropdownSelectOption) => void;
  option: DropdownSelectOption | undefined;
};

const textStyle: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px' /* 186.667% */,
  letterSpacing: '0.15px',
};

export default function SelectWithTitle({ title, tooltipText, divStyle, options, onSelect, option, disabled }: SelectWithTitleProps) {
  const handleOnSelect = (optionParam: DropdownSelectOption) => {
    onSelect(optionParam);
  };

  return (
    <div style={divStyle}>
      <Box display='flex' gap='4px'>
        <Typography style={{ ...textStyle }}>{title}</Typography>
        <HelpTooltip tooltipText={tooltipText} size={12} />
      </Box>
      <DropdownSelect
        disabled={disabled}
        label='Prazo de Pagamento'
        onSelect={handleOnSelect}
        boxStyle={{ height: 'auto', width: 'auto', marginTop: '4px' }}
        options={options}
        dropdownValue={option}
      />
    </div>
  );
}
