import React, { useEffect } from 'react';
import { Select, MenuItem, Box, Typography, SelectChangeEvent } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export type DropdownSelectOption = {
  label: string;
  value: string;
  defaultValue?: DropdownSelectOption;
  key?: string;
};

export type DropdownSelectProps = {
  label: string;
  dropdownValue?: DropdownSelectOption;
  options: DropdownSelectOption[];
  disabled?: boolean;
  onSelect: (option: DropdownSelectOption) => void;
  boxStyle?: React.CSSProperties;
};

const textStyle: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.80)',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px' /* 186.667% */,
  letterSpacing: '0.15px',
};

export const DropdownSelect: React.FC<DropdownSelectProps> = ({ label, options, dropdownValue, onSelect, boxStyle, disabled }) => {
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    const option = options.find((opt) => opt.value === value) || null;
    if (option) {
      onSelect(option);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        borderRadius: '5px',
        backgroundColor: disabled ? 'rgb(215, 215, 215)' : '#EEE',
        cursor: disabled ? 'auto' : 'pointer',
        width: '200px',
        ...boxStyle,
      }}
    >
      <Select
        value={dropdownValue?.value}
        onChange={handleChange}
        displayEmpty
        disabled={disabled}
        IconComponent={ArrowDropDownIcon}
        inputProps={{
          sx: {
            padding: 0,
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            padding: '14px 11px 14px 11px',
            fontSize: '14px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSvgIcon-root': {
            right: 10,
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgb(215, 215, 215)',
            '&::after': {
              width: '0%',
            },
          },
          width: '100%',
          backgroundColor: 'transparent',
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <Typography sx={{ ...textStyle }}>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
